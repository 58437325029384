import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationData } from '@metarisc/metarisc-js';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-paginate',
  standalone: true,
  imports: [NgbModule, NgIf],
  templateUrl: './paginate.component.html',
  styleUrl: './paginate.component.scss',
})
export class PaginateComponent {
  @Input({ required: true }) pagination?: PaginationData;

  @Output() loadPage = new EventEmitter<number>();

  changePage(page: number): void {
    this.loadPage.emit(page);
  }
}
