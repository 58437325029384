import { NgIf, NgStyle } from '@angular/common';
import { Component } from '@angular/core';
import { EventApi } from '@fullcalendar/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-calendar-event-modal',
  templateUrl: './calendar-event-modal.component.html',
  styleUrls: ['./calendar-event-modal.component.scss'],
  standalone: true,
  imports: [NgIf, NgStyle],
})
export class CalendarEventModal {
  calendarEvent!: EventApi;

  constructor(private activeModal: NgbActiveModal) {}

  getStartDate(): string {
    if (!this.calendarEvent.start) return 'Aucune date de fin';
    const dateStart = this.calendarEvent.start.toLocaleDateString('fr-FR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const timeStart = this.calendarEvent.start.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });

    return `${dateStart}${timeStart === '00:00' ? ' toute la journée' : ' à ' + timeStart}`;
  }

  getEndDate(): string {
    if (!this.calendarEvent.end) return 'Aucune date de fin';
    const dateEnd = this.calendarEvent.end.toLocaleDateString('fr-FR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const timeStart = this.calendarEvent.end.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });

    return `${dateEnd}${timeStart === '00:00' ? ' toute la journée' : ' à ' + timeStart}`;
  }

  dismissModal(): void {
    this.activeModal.dismiss();
  }
}
