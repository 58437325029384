import { Injectable } from '@angular/core';
import { AuthMethod } from '@metarisc/metarisc-js/lib/client';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { MetariscService } from '../metarisc-service/metarisc.service';
import { ToastNotificationService } from '../toast-notification-service/toast-notification.service';
import { GrantResponse } from '@metarisc/metarisc-js/lib/auth/oauth2';
import { Utilisateur } from '@metarisc/metarisc-js/lib/model/Utilisateur';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

interface RefreshResponse {
  access_token: string;
  token_type: string;
  expires_in?: number;
  refresh_token?: string;
  scope?: string;
  id_token?: string;
  refresh_expires_in?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static ACCESS_TOKEN_COOKIE_NAME = 'access_token';
  static REFRESH_TOKEN_COOKIE_NAME = 'refresh_token';
  static ID_TOKEN_COOKIE_NAME = 'id_token';

  constructor(
    private metariscService: MetariscService,
    private cookieService: CookieService,
    private toastNotificationService: ToastNotificationService,
    private http: HttpClient,
    private router: Router,
  ) {}

  async getConnectedUser(): Promise<Utilisateur> {
    const utilisateur = await this.metariscService.getMetarisc().moi?.moi();
    if (utilisateur) {
      return utilisateur.data;
    } else {
      throw new Error("Erreur lors de la récupération des données de l'utilisateur connecté");
    }
  }

  async retrieveToken(code: string): Promise<void> {
    const response = await this.metariscService.getMetarisc().authenticate(AuthMethod.AUTHORIZATION_CODE, {
      redirect_uri: environment.paramAuth.redirectUri,
      code: code,
      scope: environment.paramAuth.scope,
    });
    if (response.error) {
      this.toastNotificationService.error('Erreur lors de votre authentification (' + response.error + ')');
    } else {
      this.setGrantResponseCookie(response);
    }
  }

  setGrantResponseCookie(token: GrantResponse): void {
    const expireDate = new Date(new Date().getTime() + (token.expires_in ? 1000 * token.expires_in : 0));

    if (token.access_token) {
      this.setCookieValue(
        AuthService.ACCESS_TOKEN_COOKIE_NAME,
        token.token_type + ' ' + token.access_token,
        expireDate,
      );
    }
    if (token.id_token) {
      this.setCookieValue(AuthService.ID_TOKEN_COOKIE_NAME, token.id_token, expireDate);
    }
    if (token.refresh_token) {
      const refreshExpireDate = new Date(
        new Date().getTime() + (token.refresh_expires_in ? 1000 * token.refresh_expires_in : 0),
      );
      this.setCookieValue(AuthService.REFRESH_TOKEN_COOKIE_NAME, token.refresh_token, refreshExpireDate);
    }
  }

  setRefreshResponseCookie(token: RefreshResponse): void {
    console.log(token);
    const expireDate = new Date(new Date().getTime() + (token.expires_in ? 1000 * token.expires_in : 0));
    const refreshExpireDate = new Date(
      new Date().getTime() + (token.refresh_expires_in ? 1000 * token.refresh_expires_in : 0),
    );
    if (token.access_token) {
      this.setCookieValue(
        AuthService.ACCESS_TOKEN_COOKIE_NAME,
        token.token_type + ' ' + token.access_token,
        expireDate,
      );
    }

    if (token.refresh_token) {
      this.setCookieValue(AuthService.REFRESH_TOKEN_COOKIE_NAME, token.refresh_token, refreshExpireDate);
    }
  }

  getCookieValue(name: string): string | undefined {
    return this.cookieService.check(name) ? this.cookieService.get(name) : undefined;
  }

  getAccessToken(): string | undefined {
    return this.getCookieValue(AuthService.ACCESS_TOKEN_COOKIE_NAME);
  }

  getRefreshToken(): string | undefined {
    return this.getCookieValue(AuthService.REFRESH_TOKEN_COOKIE_NAME);
  }

  getIdToken(): string | undefined {
    return this.getCookieValue(AuthService.ID_TOKEN_COOKIE_NAME);
  }

  setCookieValue(name: string, value: string, expirationDate: Date): void {
    this.cookieService.set(name, value, expirationDate, '/', undefined, true, 'Strict');
  }

  isConnected(): boolean {
    return (
      this.cookieService.check(AuthService.ACCESS_TOKEN_COOKIE_NAME) &&
      this.cookieService.check(AuthService.REFRESH_TOKEN_COOKIE_NAME)
    );
  }

  setMetariscToken(): void {
    const access_token = this.getAccessToken();
    if (access_token) this.metariscService.getMetarisc().setAccessToken(access_token);
    const refresh_token = this.getRefreshToken();
    if (refresh_token) this.metariscService.getMetarisc().setRefreshToken(refresh_token);
  }

  logout(): void {
    const logoutUrl = environment.logoutURL;
    const data = {
      client_id: environment.paramAuth.clientId,
      refresh_token: this.getRefreshToken() || '',
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `${this.getAccessToken()}`,
      }),
      withCredentials: true,
    };
    const corsDisabledOptions = { ...httpOptions, observe: 'response' as const };
    this.http.post(logoutUrl, new HttpParams({ fromObject: data }), corsDisabledOptions).subscribe({
      next: () => {
        this.cookieService.delete('access_token');
        this.cookieService.delete('refresh_token');
        this.cookieService.delete('id_token');
        window.location.replace(window.location.origin);
      },
      error: (error) => {
        this.cookieService.delete('access_token');
        this.cookieService.delete('refresh_token');
        this.cookieService.delete('id_token');
        console.error('Erreur lors de la déconnexion:', error);
      },
    });
  }
}
