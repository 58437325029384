import { Injectable } from '@angular/core';
import { Feature, Map, MapBrowserEvent, Overlay, View } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Control } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent, createEmpty, extend } from 'ol/extent';
import { Point, Polygon } from 'ol/geom';
import { Type } from 'ol/geom/Geometry';
import { Draw, Modify, Snap } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import BaseLayer from 'ol/layer/Base';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import { OSM, TileWMS, Tile, Cluster } from 'ol/source';
import VectorSource, { VectorSourceEvent } from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { Options, StyleFunction } from 'ol/style/Style';
import { FlatStyle, FlatStyleLike } from 'ol/style/flat';
import { BehaviorSubject, Observable, Subject, filter, first, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private osmLayer: TileLayer<OSM> = new TileLayer({ source: new OSM() });
  private map!: Map;
  constructor() {
    this.map = new Map({});
    this.osmLayer.set('name', 'OSM');
  }
  /**
   * Initialise la carte
   * @param map Map
   */
  initMap(map?: Map): Map {
    //si la map existe (si on a déjà une map provenant d'une autre page par exemple), on efface son contenu (pour éviter les fuites de mémoire)
    if (this.map) {
      this.map.dispose();
    }
    //si on ne passe pas de map en paramètre, on utilise la map par défaut
    if (map) {
      this.map = map;
    }
    //ajout du layer open street map
    this.map.addLayer(this.osmLayer);
    //on définit la vue de la carte (par defaut, on affiche la france)
    this.map.setView(
      new View({
        center: fromLonLat([2.454071, 47.279229]),
        zoom: 6,
      }),
    );

    //on émet l'event click dans un observable (facilité l'utilisation de l'event click dans le service et les composants qui utilisent le service map)
    this.map.on('click', (e) => {
      this.mapClick.next(e);
    });

    return this.map;
  }
  /**
   * observable qui emet l'event click de la carte
   */
  mapClick = new Subject<MapBrowserEvent<any>>();

  /**
   * Remet la vue de la carte à la vue par défaut
   */
  resetView(): void {
    this.map.setView(
      new View({
        center: fromLonLat([2.454071, 47.279229]),
        zoom: 6,
      }),
    );
  }
  getMap(): Map {
    return this.map;
  }
  getLayers(): BaseLayer[] {
    return this.map.getLayers().getArray();
  }
  getLayer(layerName: string): Layer | undefined {
    const layerFromMap = this.map.getAllLayers().find((layer) => layer.get('name') === layerName);
    return layerFromMap;
  }

  /**
   * Retourne le VectorLayer avec le nom "layerName" s'il existe, sinon undefined
   * @param layerName le nom du layer
   */
  private getVectorLayer(layerName: string): VectorLayer<VectorSource> | undefined {
    const layerFromMap = this.map
      .getAllLayers()
      .find((layer) => layer.get('name') === layerName && layer instanceof VectorLayer) as VectorLayer<VectorSource>;
    if (layerFromMap) return layerFromMap;
    else return undefined;
  }

  /**
   * Retourne le VectorLayer avec le nom "layerName" s'il existe, sinon on le crée
   * @param layerName le nom du layer
   */
  private getOrCreateVectorLayer(layerName: string): VectorLayer<VectorSource> {
    const layerFromMap = this.map
      .getAllLayers()
      .find((layer) => layer.get('name') === layerName && layer instanceof VectorLayer);
    if (layerFromMap instanceof VectorLayer) return layerFromMap;
    else {
      const newLayer = new VectorLayer({ source: new VectorSource() });
      newLayer.set('name', layerName);
      this.map.addLayer(newLayer);

      return newLayer;
    }
  }

  /**
   * Retourne le TileLayer avec le nom "layerName" s'il existe, sinon on le crée
   * @param layerName le nom du layer
   */
  private getOrCreateTileLayer(layerName: string): TileLayer<Tile> | undefined {
    const layerFromMap = this.map
      .getAllLayers()
      .find((layer) => layer.get('name') === layerName && layer instanceof TileLayer);
    if (layerFromMap instanceof TileLayer) return layerFromMap;
    else {
      const newLayer = new TileLayer({ source: new Tile({}) });
      newLayer.set('name', layerName);
      this.map.addLayer(newLayer);

      return newLayer;
    }
  }

  /**
   * Supprime toutes les features d'un layer avec le nom "layerName"
   * @param layerName le nom du layer concerné
   */
  removeFeaturesOfLayer(layerName: string): void {
    const layer = this.getLayer(layerName);
    layer?.getSource()?.refresh();
  }

  /**
   * Permet le regroupement des points à une certaine échelle
   * @param layerName le nom du layer concerné
   * @param style le style à appliquer aux clusters (les features non regroupées gardent leur style)
   */
  createClusterVectorLayer(layerName: string, style?: StyleType): this {
    const isFunc = typeof style === 'function';
    const styleFunction = isFunc
      ? style
      : (feature: FeatureLike): Style => {
          const size = feature.get('features').length;

          const clusterStyle = style
            ? this.newStyle(style)
            : new Style({
                image: new CircleStyle({
                  radius: 10,
                  stroke: new Stroke({
                    color: '#fff',
                  }),
                  fill: new Fill({
                    color: '#3399CC',
                  }),
                }),
                text: new Text({
                  text: size > 1 ? size.toString() : '',
                  fill: new Fill({
                    color: '#fff',
                  }),
                }),
              });
          //Si il n'y a qu'un seul feature dans le cluster, on récupère son style
          if (size === 1 && typeof clusterStyle !== 'function') {
            const featureStyle = feature.get('features')[0].getStyle();
            if (featureStyle) {
              return featureStyle as Style;
            } else {
              return clusterStyle;
            }
          }
          return clusterStyle as Style;
        };
    const layer = new VectorLayer({
      source: new Cluster({
        source: new VectorSource(),
        distance: parseInt('20', 10),
        minDistance: parseInt('100', 10),
      }),
      style: styleFunction,
    });
    layer.set('name', layerName);
    this.map.addLayer(layer);
    return this;
  }
  setView(coordinate: Coordinate, zoom: number): void {
    this.map.getView().setCenter(coordinate);
    this.map.getView().setZoom(zoom);
  }

  addLayer(layer: Layer, layerName: string): Layer {
    layer.set('name', layerName);
    if (!this.map.getLayers().getArray().includes(layer)) {
      this.map.addLayer(layer);
    }
    return layer;
  }

  addControl(control: Control): void {
    this.map.addControl(control);
  }

  /**
   * Ajoute une feature au layer "LayerName"
   * @param feature la feature à ajouter
   * @param layerName le nom du layer
   * @param style le style à appliquer à la feature
   */
  addFeature(feature: Feature, layerName: string, style?: StyleType): void {
    if (style) feature.setStyle(this.newStyle(style));

    const layer = this.getOrCreateVectorLayer(layerName);
    if (layer.getSource() instanceof Cluster) (layer.getSource() as Cluster)?.getSource()?.addFeature(feature);
    else layer.getSource()?.addFeature(feature);
  }

  /**
   * Ajoute une feature de type point au layer "LayerName" à partir de coordonnées
   * @param layerName le nom du layer
   * @param coordinate les coordonnées du point
   * @param style le style à appliquer à la feature
   */
  addPoint(layerName: string, coordinate: Coordinate, style?: StyleType): Feature {
    const feature = new Feature({ geometry: new Point(coordinate) });
    if (style) feature.setStyle(this.newStyle(style));

    const layer = this.getOrCreateVectorLayer(layerName);
    if (layer.getSource() instanceof Cluster) {
      (layer.getSource() as Cluster)?.getSource()?.addFeature(feature);
    } else layer.getSource()?.addFeature(feature);

    return feature;
  }

  /**
   * Ajoute une feature de type polygon au layer "LayerName" à partir de coordonnées
   * @param layerName le nom du layer
   * @param coordinates les coordonnées du polygon
   * @param style le style à appliquer à la feature
   */
  addPolygon(layerName: string | false, coordinates: Coordinate[][], style?: StyleType): Feature {
    const feature = new Feature({ geometry: new Polygon(coordinates) });
    if (style) feature.setStyle(this.newStyle(style));
    if (layerName) {
      const layer = this.getOrCreateVectorLayer(layerName);
      if (layer.getSource() instanceof Cluster) {
        (layer.getSource() as Cluster)?.getSource()?.addFeature(feature);
      } else layer.getSource()?.addFeature(feature);
    }
    return feature;
  }

  /**
   * Supprime une feature du layer "LayerName"
   * @param feature
   * @param layerName
   */
  removeFeature(feature: Feature, layerName: string): boolean {
    const layer = this.getVectorLayer(layerName);
    if (!layer) return false;
    const source =
      layer.getSource() instanceof Cluster ? (layer.getSource() as Cluster)?.getSource() : layer.getSource();
    if (source?.getFeatures().includes(feature)) {
      source.removeFeature(feature);
    } else return false;
    return true;
  }

  //Change le nom d'un layer
  changeLayerName(layer: Layer, newName: string): void {
    layer.set('name', newName);
  }

  /**
   * Ajoute un layer WMS à partir d'une source et de paramètres de route
   * @param source url de la source
   * @param wmsParams paramètres de la route ({LAYERS: 'DECI', FORMAT: 'image/gif'}})
   * @param layerName le nom de la couche à créer en local
   */
  addWMSLayer(source: string, wmsParams: Record<string, string>, layerName: string): Layer {
    return this.addLayer(
      new TileLayer({
        source: new TileWMS({
          url: source,
          params: wmsParams,
        }),
      }),
      layerName,
    );
  }

  /**
   * Crée un style à partir d'une donnée en paramètre, si c'est un string, on crée un style prédéfini (pei, erp), sinon on crée un style à partir des types Style | function | Options
   * @param style
   */
  newStyle(style: StyleType): Style | StyleFunction {
    if (style instanceof Style) return style;
    if (typeof style === 'function') return style;
    if (typeof style === 'string') {
      switch (style) {
        case 'pei':
          return new Style({
            image: new Icon({
              src: '/assets/images/map-pointer/deci.svg',
              width: 20,
              height: 20,
            }),
          });
        case 'erp':
          return new Style({
            image: new Icon({
              src: '/assets/images/map-pointer/erp.svg',
              width: 20,
              height: 20,
            }),
          });
      }
    } else {
      return new Style(style);
    }
  }
  /**
   * Active le zoom sur la feature cliquée
   * @param layer Permet de définir le layer dans lequel les features son zoomable, si non défini, toutes les features sont zoomable
   */
  featuresZoomOnClick(layer?: VectorLayer<VectorSource> | string): this {
    const layerObject =
      typeof layer === 'string' ? this.getOrCreateVectorLayer(layer) : layer instanceof VectorLayer ? layer : undefined;

    this.map.on('click', (e) => {
      const features = this.map.getFeaturesAtPixel(e.pixel);
      if (!features.length) return;

      const clusterMembers = features[0].get('features');
      const featuresToZoom = clusterMembers && clusterMembers.length > 1 ? clusterMembers : features;

      const extent = createEmpty();
      featuresToZoom.forEach((feature: FeatureLike) => {
        const extentFeature = feature.getGeometry()?.getExtent();
        if (extentFeature) extend(extent, extentFeature);
      });

      if (
        extent &&
        (featuresToZoom.length === 1 ||
          !layerObject ||
          layerObject
            .getSource()
            ?.getFeatures()
            .includes(featuresToZoom[0] as Feature))
      ) {
        this.map.getView().fit(extent, { duration: 500, maxZoom: 16, padding: [200, 200, 200, 200] });
      }
    });
    return this;
  }

  /**
   * Zoom sur une feature précise
   * @param feature
   * @param duration durée de l'animation
   */
  zoomToFeature(feature: Feature, duration = 500, zoom = 16): void {
    const extentFeature = feature.getGeometry()?.getExtent();
    if (extentFeature) {
      this.map.getView().fit(extentFeature, { duration: duration, maxZoom: zoom, padding: [200, 200, 200, 200] });
    }
  }
  /**
   * Zoom sur une liste de features
   * @param features
   * @param duration durée de l'animation
   */
  zoomToFeatures(features: Feature[], duration = 500): void {
    if (features && features.length > 0) {
      const extent = boundingExtent(features.map((feature) => feature.getGeometry()!.getExtent()));
      this.map.getView().fit(extent, { duration: duration, maxZoom: 18, padding: [50, 50, 50, 50] });
    }
  }
  /**
   *retourne un observable qui emet la feature cliqué
   */
  featureClicked = this.mapClick.pipe(
    filter((e) => this.map.getFeaturesAtPixel(e.pixel).length === 1),
    map((e) => {
      return this.map.getFeaturesAtPixel(e.pixel)[0] as Feature;
    }),
    filter((e) => {
      //on enlève cluster
      return !this.isCluster(e);
    }),
    map((e) => {
      //Si la feature est dans un cluster, on récupère sa feature enfant
      if (e.get('features')) {
        return e.get('features')[0];
      }
      return e;
    }),
  );

  /**
   *
   * @param layerName
   * @param type
   * @param arrayFeaturesRef Correspond à un tableau de features, si défini, on ajoute les features dessinées dans ce tableau
   * @param style Si défini, on applique ce style au layer et au draw  {layerStyle: Style | FlatStyle, DrawStyle: Style | FlatStyle}
   * @returns Observable qui emet la feature dessinée à l'évenement drawend
   */
  drawingInteraction(
    layerName: string,
    option: {
      type: Type;
      unique?: boolean;
      arrayFeaturesRef?: Feature[] | BehaviorSubject<Feature[]>;
      style?: Style | FlatStyleLike | drawingStyle;
    },
  ): Observable<DrawEvent | ModifyEvent> {
    const style = option.style;
    const arrayFeaturesRef = option.arrayFeaturesRef;
    const layer = this.getOrCreateVectorLayer(layerName);
    const source = layer.getSource() as VectorSource;
    this.addLayer(layer, layerName);
    const modify = new Modify({ source, style: style && this.isDrawingStyleType(style) ? style.DrawStyle : style });
    this.map.addInteraction(modify);

    const draw = new Draw({
      source,
      type: option.type,
      style: style && this.isDrawingStyleType(style) ? style.DrawStyle : style,
    });
    this.map.addInteraction(draw);
    const snap = new Snap({ source: source });
    this.map.addInteraction(snap);
    if (option.unique) {
      draw.on('drawstart', () => {
        source.clear();
      });
    }
    if (arrayFeaturesRef) {
      if (!(arrayFeaturesRef instanceof Subject)) {
        source.on('addfeature', (e) => {
          if (e.feature) arrayFeaturesRef.push(e.feature);
        });
        source.on('removefeature', (e) => {
          if (e.feature) arrayFeaturesRef.splice(arrayFeaturesRef.indexOf(e.feature), 1);
        });
      } else {
        source.on('addfeature', (e) => {
          if (e.feature) arrayFeaturesRef.next([...arrayFeaturesRef.getValue(), e.feature]);
        });
        source.on('removefeature', (e) => {
          if (e.feature) {
            const features = arrayFeaturesRef.getValue();
            features.splice(features.indexOf(e.feature), 1);
            arrayFeaturesRef.next(features);
          }
        });
      }
    }
    return new Observable((observer) => {
      draw.on('drawend', (e) => {
        observer.next(e);
      });
      modify.on('modifyend', (e) => {
        observer.next(e);
      });
    });
  }

  /**
   * @description Permet d'écouter les features d'un layer (création et suppression des features)
   *
   * Attention: ne pas apporter de modification aux features émises par l'observable
   * Cela créerait une boucle infinie
   */
  listenFeaturesOfLayer(layerName: string): Observable<VectorSourceEvent> {
    const layer = this.getOrCreateVectorLayer(layerName);
    return new Observable((observer) => {
      layer.getSource()?.on('addfeature', (vectorSourceEvent) => {
        observer.next(vectorSourceEvent);
      });
      layer.getSource()?.on('removefeature', (vectorSourceEvent) => {
        observer.next(vectorSourceEvent);
      });
      layer.getSource()?.on('changefeature', (vectorSourceEvent) => {
        observer.next(vectorSourceEvent);
      });
    });
  }

  /**
   *vérifie si le style est de type drawingStyle, afin de pouvoir avoir un style pour le layer et un style pour le draw
   */

  private isDrawingStyleType(style: Style | FlatStyleLike | drawingStyle): style is drawingStyle {
    return (style as drawingStyle).layerStyle !== undefined;
  }
  private isCluster(feature: Feature): boolean {
    if (!feature || !feature.get('features')) {
      return false;
    }
    return feature.get('features').length > 1;
  }
  /**
   * Ajoute un popup à la feature choisit
   * @param feature
   * @param popupContent
   */
  addPopup(feature: Feature, popupContent: string | HTMLElement): void {
    const container = document.createElement('div');
    container.classList.add('ol-popup-container');
    const closer = document.createElement('a');
    closer.classList.add('ol-popup-closer');
    const content = document.createElement('div');
    content.classList.add('ol-popup-content');
    container.appendChild(closer);
    container.appendChild(content);
    const coordinates = (feature.getGeometry() as Point).getCoordinates();

    //si popupContent est une string, on l'ajoute au innerHTML de content, sinon on l'ajoute en tant qu'enfant à l'élément
    typeof popupContent === 'string' ? (content.innerHTML = popupContent) : content.appendChild(popupContent);

    const overlay = new Overlay({
      element: container,
      position: coordinates,
    });
    const removeOverlay = (): Overlay | undefined => {
      return this.map.removeOverlay(overlay);
    };

    //Détecte si on clique sur le bouton de fermeture ou sur la map
    closer.addEventListener('click', removeOverlay);
    this.mapClick.pipe(first()).subscribe(removeOverlay);

    this.map.addOverlay(overlay);
  }
}
type drawingStyle = {
  layerStyle: Style | FlatStyle;
  DrawStyle: Style | FlatStyle;
};
type StyleType = Style | Options | StyleFunction | StyleStringType;
type StyleStringType = 'erp' | 'pei';
