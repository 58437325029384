<div class="d-flex">
  <span class="badge badge-title" [ngClass]="getBadgeClass()">{{ data.descriptif_technique?.type }}</span>
  <span
    *ngIf="clickable"
    class="badge badge-content bg-secondary hover-click"
    routerLink="/deci/fiche-pei/{{ data.id }}"
    >{{ data.numero }}</span
  >
  <span *ngIf="!clickable" class="badge badge-content bg-secondary ff-medium">{{ data.numero }}</span>
</div>
