import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Collection } from '@metarisc/metarisc-js';
import { PaginationData } from '@metarisc/metarisc-js/lib/collection';
import { Dossier } from '@metarisc/metarisc-js/src/model/Dossier';
import { Tag } from '@metarisc/metarisc-js/src/model/Tag';
import { DossierComplet } from 'src/app/models/dossier.model';
import { MetariscService } from 'src/app/services/metarisc-service/metarisc.service';
import { ListDossierItemComponent } from 'src/app/shared/components/lists-item/list-dossier-item/list-dossier-item.component';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { NoResultComponent } from 'src/app/shared/components/no-result/no-result.component';
import { RecapDossierComponent } from 'src/app/shared/components/recap-dossier/recap-dossier.component';
import { UtilitairesService } from 'src/app/services/utilitaires-service/utilitaires.service';
import { PaginateComponent } from 'src/app/shared/components/paginate/paginate.component';

@Component({
  templateUrl: './suivi-des-dossiers-page.component.html',
  styleUrls: ['./suivi-des-dossiers-page.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ListDossierItemComponent,
    NoResultComponent,
    RecapDossierComponent,
    LoadingComponent,
    PaginateComponent,
  ],
})
export class SuiviDesDossiersPage implements OnInit {
  nbDossierByPage = 10;

  dossiers: DossierComplet[] = [];
  pagination?: PaginationData;
  loading?: Promise<unknown>;

  metariscClient = this.metariscService.getMetarisc();
  dossierCollection?: Collection<Dossier>;

  constructor(
    private metariscService: MetariscService,
    private utilService: UtilitairesService,
  ) {}

  ngOnInit(): void {
    this.dossierCollection = this.metariscClient.dossiers?.paginateDossiers(1, 10);
    this.loadPage(1);
  }

  loadPage(page: number): void {
    this.utilService.scrollTop();

    this.loading = this.dossierCollection?.fetchPage(page, this.nbDossierByPage).then(async (response) => {
      this.dossiers = [];
      // Mise à jour des données de notification
      if (response.data.data && response.data.data.length > 0) {
        for await (const dossier of response.data.data) {
          const dossierComplet = { dossier: dossier } as DossierComplet;
          this.dossiers.push(dossierComplet);
          this.getTags(dossierComplet);
        }
      }
      // Mise à jour des données de pagination
      this.pagination = response.data.meta?.pagination as PaginationData;
    });
  }

  async getTags(dossier: DossierComplet): Promise<void> {
    if (dossier.dossier.id) {
      this.metariscClient.dossiers
        ?.paginateDossierTags(dossier.dossier.id, 1, 10)
        .autoPagingIteratorToArray()
        .then((tags: Tag[]) => {
          dossier.tags = tags;
        });
    }
  }
}
