import { NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, filter, fromEvent, iif, map, switchMap } from 'rxjs';
import { Adresse } from 'src/app/services/api-geo-gouv-service/api-adresse-gouv.model';
import { ApiGeoGouvService } from 'src/app/services/api-geo-gouv-service/api-geo-gouv.service';

type fnOnChange = (_: string | undefined) => void;

@Component({
  selector: 'app-adresse-autocomplete',
  templateUrl: './adresse-autocomplete.component.html',
  styleUrls: ['./adresse-autocomplete.component.scss'],
  standalone: true,
  imports: [NgClass],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: AdresseAutocompleteControlValueAccessor, multi: true }],
})
export class AdresseAutocompleteControlValueAccessor implements AfterViewInit, ControlValueAccessor {
  @Input() placeholder = 'Recherchez une adresse';
  @Input() addStyle?: string;
  @Input() postalCode = '';
  @Input() coordinate?: number[];

  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('listItems') listItems!: ElementRef;
  @ViewChild('searchbox') searchBox!: ElementRef;

  @Output() featureSelected = new EventEmitter<Adresse>();
  @Output() addressString = new EventEmitter<string>();

  apiGouv = inject(ApiGeoGouvService);

  features: Adresse[] = [];
  address = '';

  onChange: fnOnChange = () => {
    // do nothing
  };
  onTouch: () => void = () => {
    // do nothing
  };
  disabled = false;

  constructor() {}

  writeValue(adresse: string): void {
    this.address = adresse || '';
  }

  registerOnChange(fn: fnOnChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  ngAfterViewInit(): void {
    document.addEventListener('click', (event) => {
      const outsideClick = !this.searchBox.nativeElement.contains(event.target);
      if (outsideClick) {
        this.features = [];
      } else {
        (this.searchInput.nativeElement as HTMLInputElement).dispatchEvent(new Event('input'));
      }
    });

    const inputEvent$ = fromEvent<InputEvent & { target: HTMLInputElement }>(this.searchInput.nativeElement, 'input');
    const keyupInputEvent$ = fromEvent(this.searchInput.nativeElement, 'keyup');
    const keyupListItemsEvent$ = fromEvent(this.listItems.nativeElement, 'keyup');
    const insideInputEvent$ = fromEvent(this.searchInput.nativeElement, 'focus');
    const focusOut: Observable<FocusEvent> = fromEvent(this.searchBox.nativeElement, 'focusout');

    focusOut.subscribe(() => {
      // do nothing
    });

    insideInputEvent$.subscribe(() => {
      (this.searchInput.nativeElement as HTMLInputElement).dispatchEvent(new Event('input'));
      (this.listItems.nativeElement.querySelectorAll('.selected-item') as HTMLDivElement[]).forEach((item) => {
        item.classList.remove('selected-item');
        const selectionEnd = (this.searchInput.nativeElement as HTMLInputElement).value.length;

        (this.searchInput.nativeElement as HTMLInputElement).setSelectionRange(selectionEnd, selectionEnd);
      });
    });
    inputEvent$
      .pipe(
        filter((e) => {
          const regexPoint = /^(-?\d{1,2}\.\d+)[,;]\s*(-?\d{1,3}\.\d+)$/;
          const regexComma = /^(-?\d{1,2},\d+);\s*(-?\d{1,3},\d+)$/;
          const regexTrue = regexPoint.test(e.target.value) || regexComma.test(e.target.value);

          if (regexTrue) {
            this.features = [];
            return false;
          }
          return this.searchInput.nativeElement.value.trim().length >= 3 && !regexTrue;
        }),

        switchMap(() =>
          iif(
            () => !!this.coordinate,
            this.apiGouv.searchAdresseWithCoordinates(this.searchInput.nativeElement.value, this.coordinate, 5),
            this.apiGouv.getAdresse(this.searchInput.nativeElement.value, 5, this.postalCode),
          ),
        ),
        map((result) => {
          return result.features;
        }),
      )
      .subscribe((features) => {
        this.features = features;
      });

    keyupInputEvent$.subscribe((event) => {
      this.onTouch();
      this.onChange(this.searchInput.nativeElement.value);
      const eventTyped = event as KeyboardEvent & { target: HTMLInputElement };
      if (eventTyped.key === 'ArrowDown') {
        const listItems = this.listItems.nativeElement;
        const firstItem = listItems.firstElementChild;

        if (firstItem) {
          listItems.focus();
          firstItem.classList.add('selected-item');
        }
      }
      if (eventTyped.key === 'ArrowUp') {
        const listItems = this.listItems.nativeElement;
        const lastItem = listItems.lastElementChild;

        if (lastItem) {
          listItems.focus();
          lastItem.classList.add('selected-item');
        }
      }

      if (eventTyped.key === 'Enter') {
        if (this.features.length > 0) {
          this.returnFeature(this.features[0]);
        } else {
          this.addressString.emit(this.searchInput.nativeElement.value);
        }
      }
    });

    keyupListItemsEvent$.subscribe((event) => {
      const listItems = this.listItems.nativeElement;
      const selectedItems = listItems.getElementsByClassName('selected-item');

      const selectedItem = selectedItems[0];

      if ((event as KeyboardEvent).key === 'ArrowDown') {
        const nextItem = selectedItem?.nextElementSibling;

        if (nextItem) {
          selectedItem.classList.remove('selected-item');
          nextItem.classList.add('selected-item');
        } else {
          this.searchInput.nativeElement.focus();
          this.listItems.nativeElement.querySelector('.selected-item')?.classList.remove('selected-item');
        }
      }

      if ((event as KeyboardEvent).key === 'ArrowUp') {
        const previousItem = selectedItem.previousElementSibling;

        if (previousItem) {
          selectedItem.classList.remove('selected-item');
          previousItem.classList.add('selected-item');
        } else {
          this.searchInput.nativeElement.focus();
          this.listItems.nativeElement.querySelector('.selected-item')?.classList.remove('selected-item');
        }
      }

      if ((event as KeyboardEvent).key === 'Enter') {
        const index = Array.from(listItems.children).indexOf(selectedItem);
        const feature = this.features[index];
        this.returnFeature(feature);
      }

      if ((event as KeyboardEvent).key === 'Escape') {
        this.searchInput.nativeElement.focus();
      }
    });
  }

  returnFeature(feature: Adresse): void {
    this.features = [];
    this.address = feature.properties.label;
    this.onChange(this.address);
    this.onTouch();
    this.searchInput.nativeElement.value = this.address;
    this.addressString.emit(this.address);
    this.featureSelected.emit(feature);
  }
}
