import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

export type NoResultMessage = {
  title: string;
  description: string;
};

@Component({
  selector: 'app-no-result',
  templateUrl: './no-result.component.html',
  styleUrls: ['./no-result.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class NoResultComponent {
  @Input() title = 'Aucun résultat trouvé';
  @Input() description = '';
}
