<div #searchbox id="search-box">
  <input
    #searchInput
    class="form-control"
    [style]="addStyle"
    type="text"
    [placeholder]="placeholder"
    [value]="address"
    autocomplete="none"
    [disabled]="disabled"
  />
  <div tabindex="0" #listItems id="autocomplete-list" [ngClass]="{ 'd-none': !features.length }">
    @for (feature of features; track $index) {
      <div class="autocomplete-item" (click)="returnFeature(feature)" (keyup)="returnFeature(feature)">
        {{ feature.properties.label }}
      </div>
    }
  </div>
</div>
