import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavMenuComponent, Tab } from 'src/app/shared/components/nav-menu/nav-menu.component';

@Component({
  templateUrl: './recent-activity-layout.component.html',
  styleUrls: ['./recent-activity-layout.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NavMenuComponent],
})
export class RecentActivityLayout {
  tabs: Tab[] = [];

  constructor() {
    // Initialisation de la liste des onglets pour la navigation
    this.tabs = [
      { label: "Flux d'activité", url: 'flux' },
      //{ label: 'Tableau de bord', url: 'tableau-de-bord' },
      //{ label: 'Inondations', url: 'evenement-special/inondations-pas-de-calais' },
    ];
  }
}
