import { Injectable } from '@angular/core';
import parse from 'rss-to-json';
import { Observable, from, map } from 'rxjs';

export type rssItem = {
  title: string;
  link: string;
  created: number;
};

@Injectable({
  providedIn: 'root',
})
export class RssFeedService {
  getFeed(rss: string): Observable<rssItem[]> {
    return from(parse(rss)).pipe(
      map((feed) => feed.items.map((item) => ({ title: item.title, link: item.link, created: item.created }))),
    );
  }
}
