@if (role) {
  <div class="d-flex flex-row gap-1">
    <div>
      <a
        [routerLink]="'/organisation/' + organisation.id"
        class="organisation d-flex align-items-center gap-2 stretched-link"
      >
        <img [src]="organisation.logo_url || 'https://via.placeholder.com/150'" style="max-height: 80px; width: 80px" />
      </a>
    </div>
    <div class="mt-1">
      <a
        [routerLink]="'/organisation/' + organisation.id"
        class="organisation d-flex align-items-center gap-2 stretched-link"
      >
        <div class="organisation-details">
          <div class="badge bg-secondary text-white ff-medium lh-1 rounded-1 px-1 py-0_5 mb-1">{{ role }}</div>
          <div class="organisation-name ff-medium">{{ organisation.nom }}</div>
          <div *ngIf="organisation.type" class="fw-light fs-6">Type : {{ organisation.type }}</div>
        </div>
      </a>
    </div>
  </div>
} @else {
  <div class="frosted-glass p-2">
    <div class="card-body">
      <a
        [routerLink]="'/organisation/' + organisation.id"
        class="organisation d-flex align-items-center gap-2 stretched-link"
      >
        <img [src]="organisation.logo_url || 'https://via.placeholder.com/150'" style="max-height: 80px; width: 80px" />
        <div class="organisation-details">
          <div class="organisation-name ff-medium">{{ organisation.nom }}</div>
          <div *ngIf="organisation.type" class="fw-light fs-6">Type : {{ organisation.type }}</div>
        </div>
      </a>
    </div>
  </div>
}
