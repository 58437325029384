import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet],
})
export class LoadingComponent implements OnChanges {
  @Input({ required: true }) load?: boolean | Promise<unknown> = false;
  @Input() display: 'inline' | 'block' = 'block';
  @Input() placeholder?: TemplateRef<Element>;
  @Input() size?: 'small' | 'medium' | 'large' = 'medium';
  @Input() color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' = 'primary';
  @Input() errorMessage?: string;
  @Input() error?: string;
  @Input() set repeat(value: number) {
    this.array = Array(value | 1);
  }
  array = Array(1);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['load']) {
      this.error = undefined;
      const load = this.load;
      // Si la valeur de load est une promesse, on affiche le loader
      // Puis à la résolution on l'enlève
      // Si la promesse est rejetée où quelle retourne un objet Error, on affiche l'erreur
      if (load instanceof Promise) {
        this.load = true;
        load
          .then((resultats) => {
            if (resultats instanceof Error) {
              throw new Error(resultats.message);
            } else {
              this.load = false;
            }
          })
          .catch((error) => {
            if (this.errorMessage) {
              this.error = this.errorMessage;
            } else this.error = 'Une erreur est survenue : ' + error.message;
          });
      }
    }
  }
}
