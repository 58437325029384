import { NgClass, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Notification } from '@metarisc/metarisc-js/src/model/Notification';
import { MetariscService } from 'src/app/services/metarisc-service/metarisc.service';
import { DateFromNowPipe } from 'src/app/shared/pipes/date-from-now.pipe';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, DateFromNowPipe],
})
export class NotificationComponent {
  @Input() isPlaceholder = false;
  @Input() data!: Notification;

  meta = inject(MetariscService);

  readed(): void {
    if (this.data.id)
      this.meta
        .getMetarisc()
        .notifications?.notificationMarquerCommeLue(this.data.id)
        .then((data) => {
          this.data = data.data;
        });
  }

  getType(title: string): string {
    if (title.includes('Dossier')) return 'dossier';
    if (title.includes('PEI')) return 'pei';
    if (title.includes('ERP')) return 'erp';
    if (title.includes('Anomalie')) return 'anomalie';
    return '-';
  }
}
