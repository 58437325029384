  <ng-container *ngIf="isPlaceholder; else display_data">
    <div class="notification d-flex placeholder-glow gap-3 p-1">
      <div class="notification-image rounded placeholder"></div>
      <div class="flex-grow-1 placeholder-glow">
        <div class="d-flex justify-content-between placeholder-glow mb-2">
          <span class="notification-title fs-5 placeholder col-5"></span>
          <span class="notification-date placeholder col-2"></span>
        </div>
        <span class="placeholder col-9"></span>
      </div>
    </div>
  </ng-container>
  <ng-template #display_data>
    <div *ngIf="data" class="notification d-flex gap-3 p-1">
      <img src="/assets/images/metadome.svg" alt="" class="notification-image rounded" />
      <div class="flex-grow-1">
        <div class="d-flex justify-content-between mb-1">
          <span class="notification-title fs-5">{{ data.titre }}</span>
          <span class="notification-date">{{ data.date_de_creation || 0 | dateFromNow }}</span>
        </div>
        {{ data.texte }}
      </div>
    </div>
  </ng-template>
