<div class="d-flex align-items-center mb-1_5">
  <i class="bi bi-exclamation-triangle fs-1 me-2 lh-1"></i>
  <span class="fs-3 lh-1 ff-medium">INONDATIONS DANS LE PAS-DE-CALAIS</span>
</div>

<div class="alert alert-title py-1 mb-3" role="alert" style="width: max-content; max-width: 100%">
  <div class="d-flex align-items-center gap-2 lh-1">
    <i class="bi bi-exclamation-diamond-fill text-warning fs-3"></i>
    <span>
      Crues vigilance orange : Risque de crue génératrice de débordements importants susceptibles d’avoir un impact
      significatif sur la vie collective et la sécurité des biens et des personnes.
    </span>
  </div>
</div>

<!-- <ngx-masonry [options]="myMasonryOptions">
  <div ngxMasonryItem class="grid-item grid-item-map frosted-glass p-1_5">
    <h5 class="mb-1_5">Carte météo :</h5>
    <div class="h-100">
      <app-map></app-map>
    </div>
  </div>
  <div ngxMasonryItem class="grid-item grid-item-search frosted-glass">
    <div class="header d-flex justify-content-between align-items-center frosted-glass py-2 px-3">
      <span class="fs-5">
        ERP en zone inondable
        <span class="counter ms-2">121</span>
      </span>
      <button class="btn btn-secondary">Voir la liste détaillée</button>
    </div>
    <div class="content px-3 pt-2 pb-1">
      <div class="mb-2">
        <app-list-erp-item [erpList]="erpZoneInondable"></app-list-erp-item>
      </div>
      <div
        *ngIf="paginationErpZoneInondable && paginationErpZoneInondable.total_pages > 1"
        class="d-flex justify-content-center"
      >
        <ngb-pagination
          [pageSize]="paginationErpZoneInondable.per_page"
          [collectionSize]="paginationErpZoneInondable.total"
          [(page)]="paginationErpZoneInondable.current_page"
          [maxSize]="3"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="loadPageErpZoneInondable($event)"
        ></ngb-pagination>
      </div>
    </div>
  </div>
  <div ngxMasonryItem class="grid-item grid-item-vigilance frosted-glass p-1_5">
    <h5 class="mb-2">Vigilances :</h5>
    <div class="d-flex align-items-center frosted-glass p-1 mb-2 gap-1">
      <i class="vigi-neige-verglas"></i>
      <span>Neige et verglas</span>
    </div>
    <div class="d-flex align-items-center frosted-glass p-1 mb-2 gap-1">
      <i class="vigi-crues"></i>
      <span>Crues</span>
    </div>
    <div class="d-flex align-items-center frosted-glass p-1 gap-1">
      <i class="vigi-pluies-inondations"></i>
      <span>Pluies et inondations</span>
    </div>
  </div>
  <div ngxMasonryItem class="grid-item grid-item-search frosted-glass">
    <div class="header d-flex justify-content-between align-items-center frosted-glass py-2 px-3">
      <span class="fs-5">
        ERP en capacité d'héberger
        <span class="counter ms-2">44</span>
      </span>
      <button class="btn btn-secondary">Voir la liste détaillée</button>
    </div>
    <div class="content px-3 py-2">
      <div class="mb-2">
        <app-list-erp-item [erpList]="erpCapaciteHebergement"></app-list-erp-item>
      </div>
      <div
        *ngIf="paginationErpCapaciteHebergement && paginationErpCapaciteHebergement.total_pages > 1"
        class="d-flex justify-content-center"
      >
        <ngb-pagination
          [pageSize]="paginationErpCapaciteHebergement.per_page"
          [collectionSize]="paginationErpCapaciteHebergement.total"
          [(page)]="paginationErpCapaciteHebergement.current_page"
          [maxSize]="3"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="loadPageErpCapaciteHebergement($event)"
        ></ngb-pagination>
      </div>
    </div>
  </div>
</ngx-masonry> -->

<div class="bloc row gx-3">
  <div #blocGauche class="col-lg-4 d-flex flex-column" style="min-height: 500px">
    <div class="bloc-map frosted-glass p-2 mb-2 flex-grow-1">
      <h5 class="mb-1_5 ff-medium">Carte météo :</h5>
      <div class="h-100">
        <app-map></app-map>
      </div>
    </div>
    <div class="bloc-vigilance frosted-glass p-2">
      <h5 class="mb-2 ff-medium">Vigilances :</h5>
      <div class="d-flex align-items-center frosted-glass p-1 mb-2 gap-1">
        <i class="vigi-neige-verglas"></i>
        <span>Neige et verglas</span>
      </div>
      <div class="d-flex align-items-center frosted-glass p-1 mb-2 gap-1">
        <i class="vigi-crues"></i>
        <span>Crues</span>
      </div>
      <div class="d-flex align-items-center frosted-glass p-1 gap-1">
        <i class="vigi-pluies-inondations"></i>
        <span>Pluies et inondations</span>
      </div>
    </div>
  </div>
  <div class="col-lg-8">
    <div class="bloc-search frosted-glass mb-2">
      <div class="header active d-flex justify-content-between align-items-center py-2 px-2_5">
        <span class="fs-5">
          ERP en zone inondable
          <span class="counter ms-2">121</span>
        </span>
        <button class="btn btn-secondary">Voir la liste détaillée</button>
      </div>
      <div class="content px-2_5 pt-2">
        <div class="mb-2">
          <app-list-erp-item [erpList]="erpZoneInondable"></app-list-erp-item>
        </div>
        <app-paginate
          [pagination]="paginationErpZoneInondable"
          (loadPage)="loadPageErpZoneInondable($event)"
        ></app-paginate>
      </div>
    </div>
    <div class="bloc-search frosted-glass">
      <div class="header active d-flex justify-content-between align-items-center py-2 px-2_5">
        <span class="fs-5">
          ERP en capacité d'héberger
          <span class="counter ms-2">44</span>
        </span>
        <button class="btn btn-secondary">Voir la liste détaillée</button>
      </div>
      <div class="content px-2_5 pt-2">
        <div class="mb-2">
          <app-list-erp-item [erpList]="erpCapaciteHebergement"></app-list-erp-item>
        </div>
        <app-paginate
          [pagination]="paginationErpCapaciteHebergement"
          (loadPage)="loadPageErpCapaciteHebergement($event)"
        ></app-paginate>
      </div>
    </div>
  </div>
</div>
