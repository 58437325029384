import { EnvironmentOptions } from './environmentoptions';

export const environment: EnvironmentOptions = {
  production: true,
  paramAuth: {
    issuer: 'https://id.metarisc.fr/auth/realms/production',
    redirectUri: window.location.origin + '/access',
    clientId: 'metarisc-app',
    clientSecret: '',
    responseType: 'code',
    scope: 'openid profile email',
    showDebugInformation: true,
    requireHttps: false,
  },
  logoutURL: 'https://id.metarisc.fr/auth/realms/production/protocol/openid-connect/logout?',
  apiUrl: 'https://dev.api.metarisc.fr/',
  rss: 'https://metarisc.fr/feed.xml',
  sidebar: {
    warningMessage: 'Environnement de développement',
  },
  metarisc: {
    ACCESS_TOKEN_URL: 'https://id.metarisc.fr/auth/realms/production/protocol/openid-connect/token',
    AUTHORIZATION_URL: 'https://id.metarisc.fr/auth/realms/production/protocol/openid-connect/auth',
  },
};
