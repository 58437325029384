import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Collection, PaginationData } from '@metarisc/metarisc-js';
import { ERP } from '@metarisc/metarisc-js/lib/model/ERP';
import { debounceTime, fromEvent } from 'rxjs';
import { MetariscService } from 'src/app/services/metarisc-service/metarisc.service';
import { ToastNotificationService } from 'src/app/services/toast-notification-service/toast-notification.service';
import { ListErpItemComponent } from 'src/app/shared/components/lists-item/list-erp-item/list-erp-item.component';
import { MapComponent } from 'src/app/shared/components/map/map.component';
import { PaginateComponent } from 'src/app/shared/components/paginate/paginate.component';

@Component({
  selector: 'app-special-event-dashboard-page',
  templateUrl: './special-event-dashboard-page.component.html',
  styleUrls: ['./special-event-dashboard-page.component.scss'],
  standalone: true,
  imports: [NgIf, ListErpItemComponent, MapComponent, PaginateComponent],
})
export class SpecialEventDashboardPage implements OnInit, AfterViewInit {
  erpZoneInondable: ERP[] = [];
  collectionErpZoneInondable?: Collection<ERP>;
  paginationErpZoneInondable?: PaginationData;

  erpCapaciteHebergement: ERP[] = [];
  collectionErpCapaciteHebergement?: Collection<ERP>;
  paginationErpCapaciteHebergement?: PaginationData;

  // @ViewChild(NgxMasonryComponent) masonry!: NgxMasonryComponent;

  // myMasonryOptions = {
  //   itemSelector: '.grid-item',
  //   gutter: 16,
  // };

  constructor(
    private metariscService: MetariscService,
    private toastNotificationService: ToastNotificationService,
  ) {}

  @ViewChild('blocGauche') blocGauche!: ElementRef<HTMLDivElement>;

  ngOnInit(): void {
    // Récupération des collections
    const metarisc = this.metariscService.getMetarisc();
    this.collectionErpZoneInondable = metarisc.erp?.paginateErp(1, 4);
    this.collectionErpCapaciteHebergement = metarisc.erp?.paginateErp(1, 4);
  }

  ngAfterViewInit(): void {
    // Récupération de la première page de résultats
    this.loadPageErpZoneInondable();
    this.loadPageErpCapaciteHebergement();
    setTimeout(() => {
      this.heightLeftBloc();
    });
    this.heightChange.pipe(debounceTime(100)).subscribe(() => {
      this.heightLeftBloc();
    });
  }

  //todo a executer après les requettes
  heightLeftBloc(): void {
    //position du haut du bloc dans la page
    const top = this.blocGauche.nativeElement.getBoundingClientRect().top;

    //hauteur de la fenetre
    const height = window.innerHeight;

    //calcul de la hauteur pour atteindre le bas de page
    const heightBloc = height - top - 32;

    this.blocGauche.nativeElement.style.height = heightBloc + 'px';
  }

  heightChange = fromEvent(window, 'resize');

  loadPageErpZoneInondable(page?: number, per_page = 4): void {
    if (this.collectionErpZoneInondable) {
      this.collectionErpZoneInondable
        .fetchPage(page || 1, per_page)
        .then((response) => {
          // Mise à jour resultats de la recherche
          this.erpZoneInondable = response.data.data || [];
          // Mise à jour des données de pagination
          this.paginationErpZoneInondable = response.data.meta?.pagination as PaginationData;
          // Update Masonry layout
          // this.updateMasonryLayout();
          this.heightLeftBloc();
        })
        .catch((error) => {
          this.toastNotificationService.error(
            'Erreur lors de la récupération de la liste des ERP en zone inondable (' + error.message + ')',
          );
        });
    }
  }

  loadPageErpCapaciteHebergement(page?: number, per_page = 4): void {
    if (this.collectionErpCapaciteHebergement) {
      this.collectionErpCapaciteHebergement
        .fetchPage(page || 1, per_page)
        .then((response) => {
          // Mise à jour resultats de la recherche
          this.erpCapaciteHebergement = response.data.data || [];
          // Mise à jour des données de pagination
          this.paginationErpCapaciteHebergement = response.data.meta?.pagination as PaginationData;
          // Update Masonry layout
          // this.updateMasonryLayout();
        })
        .catch((error) => {
          this.toastNotificationService.error(
            "Erreur lors de la récupération de la liste des ERP en capacité d'héberger (" + error.message + ')',
          );
        });
    }
  }

  // updateMasonryLayout(): void {
  //   this.masonry.reloadItems();
  //   this.masonry.layout();
  // }
}
