<div
  *ngIf="isPlaceholder || data"
  class="frosted-glass overflow-hidden"
  [ngClass]="{ 'border-primary': isPlaceholder || (data && !data.date_de_lecture) }"
  (click)="data && !data.date_de_lecture && readed()"
>
  <ng-container *ngIf="isPlaceholder; else display_data">
    <div class="notification d-flex placeholder-glow gap-1 p-1">
      <div class="notification-image rounded placeholder"></div>
      <div class="flex-grow-1 placeholder-glow">
        <div class="d-flex justify-content-between placeholder-glow mb-2">
          <span class="notification-title fs-5 placeholder col-5"></span>
          <span class="notification-date placeholder col-2"></span>
        </div>
        <span class="placeholder col-9"></span>
      </div>
    </div>
  </ng-container>
  <ng-template #display_data>
    <div *ngIf="data" class="notification d-flex gap-1">
      <!-- <img src="assets/images/icon/info-square.svg" alt="" class="notification-image rounded" /> -->
      <div class="notification-type" [ngClass]="getType(data.title ?? '') + ' notification-type'">
        <div>{{ getType(data.title ?? '') }}</div>
      </div>
      <div class="flex-grow-1 p-1">
        <div class="d-flex justify-content-between mb-1">
          <span class="notification-title ff-semi-bold fs-5">{{ data.title }}</span>
          <span *ngIf="data.date_creation" class="notification-date text-muted">{{
            data.date_creation | dateFromNow
          }}</span>
        </div>
        {{ data.message }}
      </div>
    </div>
  </ng-template>
</div>
