<div class="p-4 vh-100 bg-light d-flex flex-column overflow-y-auto position-relative">
  <div class="logo position-absolute"><i class="metadome"></i></div>

  <app-loading [load]="loading" size="large" style="padding: 0 60px">
    <div *ngIf="organizations?.length" class="text-center flex-grow-1">
      <div class="h-100 align-content-center justify-content-center align-items-center gap-4">
        <div class="mb-6" style="padding: 0 50px">
          <h1 class="ff-medium">Quelle organisation souhaitez-vous rejoindre ?</h1>
          <h3 class="ff-medium">Sélectionnez l'espace de travail sur lequel vous voulez vous connecter</h3>
        </div>
        <div class="d-inline-block mw-100">
          <div class="frosted-glass p-6 mx-auto flex-wrap mw-100" style="width: max-content">
            <div class="d-flex gap-4 justify-content-center flex-wrap">
              @for (organization of organizations; track $index) {
                <div
                  class="frosted-glass p-4 cursor-pointer"
                  [ngClass]="{ 'border border-2': organization.id === selected_id }"
                  (click)="organization.id ? (selected_id = organization.id) : null"
                >
                  <div class="organization-image rounded-circle mb-4">
                    <img [src]="organization.logo_url" alt="" />
                  </div>
                  <div>
                    <h4 class="ff-medium mb-3 lh-1">{{ organization.nom }}</h4>
                    <span>({{ organization.type }})</span>
                  </div>
                </div>
              }
            </div>
          </div>
          <div class="mt-4">
            <button class="btn btn-primary w-100" (click)="select()" [disabled]="!selected_id">Continuer</button>
          </div>
        </div>
      </div>
    </div>
  </app-loading>
</div>
