import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IToastNotification {
  type: ToastNotificationType;
  message: string;
  duration: number;
}

export enum ToastNotificationType {
  Success = 0,
  Warning = 1,
  Error = 2,
  Info = 3,
}

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationService {
  private notifications: Subject<IToastNotification> = new Subject();

  public success(message: string, duration?: number): void {
    this.notify(ToastNotificationType.Success, message, duration);
  }

  public persistSuccess(message: string): void {
    this.success(message, -1);
  }

  public warning(message: string, duration?: number): void {
    this.notify(ToastNotificationType.Warning, message, duration);
  }

  public persistWarning(message: string): void {
    this.warning(message, -1);
  }

  public error(message: string, duration?: number): void {
    this.notify(ToastNotificationType.Error, message, duration);
  }

  public persistError(message: string): void {
    this.error(message, -1);
  }

  public info(message: string, duration?: number): void {
    this.notify(ToastNotificationType.Info, message, duration);
  }

  public persistInfo(message: string): void {
    this.info(message, -1);
  }

  private notify(type: ToastNotificationType, message: string, duration?: number): void {
    this.notifications.next({
      type: type,
      message: message,
      duration: duration ? duration : 3000,
    } as IToastNotification);
  }

  get notification(): Observable<IToastNotification> {
    return this.notifications.asObservable();
  }
}
