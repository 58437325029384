<div class="frosted-glass news-feed p-2" *ngIf="news">
  <h5 class="ff-medium lh-1">Actualités :</h5>

  <div class="bloc-line d-flex flex-column gap-4 mt-3">
    <div *ngFor="let newsItem of news | async" class="bloc-news">
      <small class="fs-6 ff-light">{{ newsItem.created | dateFromNow }}</small>
      <a [href]="newsItem.link" target="_blank">{{ newsItem.title }}</a>
    </div>
  </div>
</div>
