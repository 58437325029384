<div class="text-secondary d-flex fs-6 ff-regular lh-1 mb-1_5 justify-content-end info-notification">
  <i class="bi bi-info-circle me-1"></i>Cliquez sur une notification pour la marquer comme lue
</div>
<app-loading size="large" [load]="loading">
  <div
    infiniteScroll
    [infiniteScrollDistance]="3"
    (scrolled)="onscroll()"
    [infiniteScrollContainer]="scrollBlock"
    [infiniteScrollThrottle]="500"
  >
    @if (notifications?.length === 0) {
      <app-no-result
        title="Pas de notification pour le moment !"
        description="En fonction de vos paramètres, vous verrez ici les mises à jour concernants vos dossiers et points adresse dans
    votre zone de compétence (et en fonction de votre profil)"
      ></app-no-result>
    } @else {
      <div class="d-flex flex-column gap-2">
        @for (notification of notifications; track $index) {
          <app-notification [data]="notification"></app-notification>
        }
      </div>
    }
  </div>
</app-loading>
