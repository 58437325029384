<h4
  class="header p-1"
  [ngStyle]="{
    'background-color': calendarEvent.backgroundColor || '#717c88',
    color: calendarEvent.textColor || '#717c88',
  }"
>
  {{ calendarEvent.title }}
</h4>
<div class="modal-body content">
  <p class="ff-medium">{{ calendarEvent.extendedProps['description'] }}</p>
  <hr />
  <dl>
    <dt>Heure de début :</dt>
    <dd>{{ getStartDate() }}</dd>
    <dt>Heure de fin :</dt>
    <dd>{{ getEndDate() }}</dd>
  </dl>
  <!--<div class="mt-2">Date de début : {{ getStartDate() }}</div>
  <div class="mt-2" *ngIf="calendarEvent.end">Date de fin : {{ getEndDate() }}</div>-->
</div>
<div class="footer d-flex justify-content-center gap-2">
  <button class="btn btn-outline-info">Modifier l'évènement</button>
  <button class="btn btn-outline-info">Supprimer l'évènement</button>
</div>
<button type="button" class="btn btn-cancel-modal" (click)="dismissModal()">
  <i class="bi bi-x-lg"></i>
</button>
