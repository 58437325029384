import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromLonLat } from 'ol/proj';
import { Adresse } from 'src/app/services/api-geo-gouv-service/api-adresse-gouv.model';
import TileLayer from 'ol/layer/Tile';
import { Control, FullScreen, ZoomSlider } from 'ol/control';
import BaseLayer from 'ol/layer/Base';
import { MapService } from 'src/app/services/map-service/map.service';
import { Feature, Map, View } from 'ol';
import Layer from 'ol/layer/Layer';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgClass, NgIf } from '@angular/common';
import { AdresseAutocompleteControlValueAccessor } from '../../forms/adresse-autocomplete/adresse-autocomplete.component';

export type AddControlModel = (DefaultControls | HTMLElement)[];
type DefaultControls = DefaultSearchControl | 'full-screen' | 'zoom-slider' | 'display-layers';
type DefaultSearchControl = 'search' | `search-${'25' | '50' | '75' | '100'}` | `search-${number}px`;

export type InitMapType = {
  view?: View | { coordinate: number[]; zoom: number };
  layers?: Layer[];
  features?: { feature: Feature; layerName: string }[];
};

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, CdkDropList, CdkDrag, AdresseAutocompleteControlValueAccessor],
})
export class MapComponent implements AfterViewInit, AfterContentInit, OnInit {
  map = this.mapService.initMap(new Map());

  constructor(private mapService: MapService) {}

  @Input() newInstance = false;
  /**
   *
   * @type {HTMLElement | "full-screen" | "zoom-slider" | "display-layers" | "search"} addControl
   */
  @Input() addControl?: AddControlModel;
  @Input() initWith?: InitMapType;
  //option pour afficher/masquer et rendre desactivable les couches sur la carte (en tant que control)
  @Input() optionsDisplayLayers?: {
    whiteList?: string[];
    blackList?: string[];
    toggle?: boolean;
    default?: boolean;
    size?: 'small' | 'medium' | 'large';
  };

  defaultLayerFeature = new TileLayer();

  @ViewChild('mapGeneral') mapGeneral!: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    //si on veut créer une nouvelle instance du service map (afin de gérer plusieurs cartes sur la même page)
    //on émet une nouvelle instance du service dans l'event mapServiceInstance
    if (this.newInstance) {
      const newSevice = new MapService();
      this.map = newSevice.initMap(new Map());
      this.mapServiceInstance.emit(newSevice);
    }
    //on lie la carte à la div mapGeneral
    this.map.setTarget(this.mapGeneral.nativeElement);
  }
  drop(event: CdkDragDrop<BaseLayer[]>): void {
    moveItemInArray(this.layers, event.previousIndex, event.currentIndex);
    this.changeZindexLayer(this.layers);
  }
  changeZindexLayer(layers: BaseLayer[]): void {
    layers.forEach((layer, index) => {
      layer.setZIndex(index);
    });
  }

  ngAfterContentInit(): void {
    if (this.initWith) {
      const { view, layers, features } = this.initWith;
      if (view) {
        view instanceof View
          ? (this.map.setView(view) as void)
          : this.map.setView(new View({ center: view.coordinate, zoom: view.zoom }));
      }
      layers
        ? layers.forEach((layer) => {
            this.map.addLayer(layer);
          })
        : null;
      features
        ? features.forEach((feature) => {
            this.mapService.addFeature(feature.feature, feature.layerName);
          })
        : null;
    }
    this.setControl();
  }

  //sert à faciliter l'affichage des controls par le template
  haveControl = { search: false, displayLayers: false };

  elementsControl?: HTMLElement[];
  defaultControl?: DefaultControls[];

  @Output() mapServiceInstance = new EventEmitter<MapService>();
  @Output() mapLoaded = new EventEmitter();
  @Output() mapClicked = new EventEmitter<string>();
  @Output() selectedAdresseString = new EventEmitter<string>();
  @Output() coordinatesInput = new EventEmitter<[number, number]>();

  ngOnInit(): void {
    //on filtre les controls pour les séparer en deux tableaux string[] et HTMLElement[]
    const defaultControl = this.addControl?.filter((control) => typeof control === 'string') as DefaultControls[];
    this.defaultControl = defaultControl?.length ? defaultControl : undefined;
    const elementsControl = this.addControl?.filter((control) => control instanceof HTMLElement) as HTMLElement[];
    this.elementsControl = elementsControl?.length ? elementsControl : undefined;
    this.mapLoaded.emit();
  }

  layers: BaseLayer[] = [];

  setControl(): void {
    this.defaultControl?.forEach((control: DefaultControls) => {
      switch (control) {
        case 'zoom-slider':
          this.map.addControl(new ZoomSlider());
          break;
        case 'full-screen':
          this.map.addControl(new FullScreen());
          break;
        case 'display-layers':
          this.haveControl.displayLayers = true;
          this.layers = this.displayLayersList();
          this.setDefaultLayerVisibility();

          break;
      }
      if (this.searchRegex(control)) {
        this.haveControl.search = true;
      }
    });

    if (this.elementsControl) {
      this.elementsControl.forEach((element: HTMLElement) => {
        this.map.addControl(
          new Control({
            element: element,
          }),
        );
      });
    }
  }
  refreshLayerList(): void {
    this.layers = this.displayLayersList();
    this.setDefaultLayerVisibility();
  }
  //on filtre les couches à afficher en fonction de la whiteList ou de la blackList
  displayLayersList(): BaseLayer[] {
    if (this.optionsDisplayLayers?.whiteList) {
      return this.mapService.getLayers().filter((e) => this.optionsDisplayLayers?.whiteList?.includes(e.get('name')));
    } else if (this.optionsDisplayLayers?.blackList) {
      return this.mapService.getLayers().filter((e) => !this.optionsDisplayLayers?.blackList?.includes(e.get('name')));
    } else {
      return this.mapService.getLayers();
    }
  }

  layerClass(): void | string {
    if (!this.optionsDisplayLayers?.size || this.optionsDisplayLayers.size === 'medium') {
      return;
    }
    if (this.optionsDisplayLayers.size === 'small') {
      return 'btn-sm';
    }
    if (this.optionsDisplayLayers.size === 'large') {
      return 'btn-lg';
    }
  }

  setDefaultLayerVisibility(): void {
    this.layers.forEach((layer) => {
      if (this.optionsDisplayLayers?.default === false) {
        layer.setVisible(false);
      }
    });
  }
  //Rends visible ou invisible une couche
  toggleLayerOnMap(layer: BaseLayer): void {
    layer.setVisible(!layer.getVisible());
  }

  //sert au style du bouton pour afficher/masquer les couches (appelé dans le template)
  isVisibleLayer(layer: BaseLayer): boolean {
    return layer.getVisible();
  }

  //on récupère l'élément de recherche que si il existe
  //Utilisation de la méthode set pour attendre que l'élément soit créé (*ngIf=true dans le template)
  //on ajoute le control à la carte en fonction des options de la barre de recherche
  searchBox?: ElementRef<HTMLDivElement>;
  @ViewChild('searchBox') set contentSearchBox(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this.searchBox = content;
      this.searchControl();
    }
  }

  //on récupère l'élément de couches que si il existe
  //Utilisation de la méthode set pour attendre que l'élément soit créé (*ngIf=true dans le template)
  //on ajoute le control à la carte
  displayLayers?: ElementRef<HTMLDivElement>;
  @ViewChild('displayLayers') set contentDisplayLayers(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this.displayLayers = content;
      this.map.addControl(
        new Control({
          element: this.displayLayers.nativeElement,
        }),
      );
    }
  }

  searchControl(): void {
    const search = this.defaultControl?.find((e) => this.searchRegex(e));
    if (search && this.searchBox) {
      this.haveControl.search = true;
      const element = this.searchBox.nativeElement;
      if (/^search$/.test(search)) {
        this.map.addControl(
          new Control({
            element: element,
          }),
        );
      } else {
        let unite = '';
        if (/^search-(25|50|75|100)$/.test(search)) {
          unite = '%';
        }
        element.style.maxWidth = `${search.split('-')[1] + unite}`;
        this.map.addControl(
          new Control({
            element: this.searchBox.nativeElement,
          }),
        );
      }
    }
  }

  //verification de la validité de la chaine de caractère pour le control de recherche
  searchRegex(control: string): boolean {
    return /^search$|^search-(25|50|75|100)$|^search-([1-9][0-9]{1,3})px$/.test(control);
  }

  searchAddress(featureSearch: Adresse): void {
    const coordonate = featureSearch.geometry.coordinates;

    if (featureSearch.properties.type === 'housenumber') {
      this.coordinatesInput.emit(coordonate as [number, number]);
      this.map.getView().setZoom(20);
    } else if (featureSearch.properties.type === 'street') {
      this.map.getView().setZoom(17);
    } else if (featureSearch.properties.type === 'locality') {
      this.map.getView().setZoom(16);
    } else {
      this.map.getView().setZoom(12);
    }
    this.map.getView().setCenter(fromLonLat(coordonate));
  }
  searchAddressString(adresse: string): void {
    this.selectedAdresseString.emit(adresse);

    const regexPoint = /^(-?\d{1,2}\.\d+)[,;]\s*(-?\d{1,3}\.\d+)$/;
    const regexComma = /^(-?\d{1,2},\d+);\s*(-?\d{1,3},\d+)$/;

    if (regexPoint.test(adresse)) {
      const coordonates = adresse
        .split(/[;,]/)
        .map((c) => parseFloat(c))
        .reverse();
      if (coordonates.every((e) => e > -90 && e < 90)) {
        this.map.getView().setZoom(19);
        this.map.getView().setCenter(fromLonLat(coordonates));
        this.coordinatesInput.emit(coordonates as [number, number]);
      }
    } else if (regexComma.test(adresse)) {
      const coordonates = adresse
        .replace(/,/g, '.')
        .split(';')
        .map((c) => parseFloat(c))
        .reverse();
      if (coordonates.every((e) => e > -90 && e < 90)) {
        this.map.getView().setZoom(20);
        this.map.getView().setCenter(fromLonLat(coordonates));
        this.coordinatesInput.emit(coordonates as [number, number]);
      }
    }
  }
}
