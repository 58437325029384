import { Injectable } from '@angular/core';
import { Metarisc, OAuth2 } from '@metarisc/metarisc-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetariscService {
  metarisc: Metarisc;

  constructor() {
    if (environment.metarisc.ACCESS_TOKEN_URL) {
      OAuth2.ACCESS_TOKEN_URL = environment.metarisc.ACCESS_TOKEN_URL;
    }

    if (environment.metarisc.AUTHORIZATION_URL) {
      OAuth2.AUTHORIZATION_URL = environment.metarisc.AUTHORIZATION_URL;
    }

    this.metarisc = new Metarisc({
      metarisc_url: environment.apiUrl,
      client_id: environment.paramAuth.clientId,
      client_secret: environment.paramAuth.clientSecret || '',
    });
  }

  getMetarisc(): Metarisc {
    return this.metarisc;
  }
}
