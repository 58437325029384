<div class="d-flex justify-content-start gap-2">
  @for (tab of tabs; track $index) {
    @if (tab.spacer) {
      <div class="spacer"></div>
    } @else {
    <div class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ disabled: tab.disabled }"
          [routerLink]="tab.url"
          routerLinkActive="active ff-medium"
        >
          {{ tab.label }}
        </a>
      </div>
    }
  }
</div>
