import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nbToArray',
  standalone: true,
})
export class NbToArrayPipe implements PipeTransform {
  transform(value: number): null[] {
    return Array(value);
  }
}
