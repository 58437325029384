import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { takeWhile } from 'rxjs';
import {
  IToastNotification,
  ToastNotificationService,
  ToastNotificationType,
} from 'src/app/services/toast-notification-service/toast-notification.service';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  standalone: true,
  imports: [],
})
export class ToastNotificationComponent implements OnInit {
  @ViewChild('notificationContainer')
  container!: ElementRef<HTMLDivElement>;

  private _subscribed = true;
  private classMap: Map<ToastNotificationType, string> = new Map<ToastNotificationType, string>();

  constructor(
    private toastNotificationService: ToastNotificationService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    // Initialisation de la transco type vers class css
    this.classMap.set(ToastNotificationType.Success, 'success');
    this.classMap.set(ToastNotificationType.Warning, 'warning');
    this.classMap.set(ToastNotificationType.Error, 'danger');
    this.classMap.set(ToastNotificationType.Info, 'info');

    this.toastNotificationService.notification.pipe(takeWhile(() => this._subscribed)).subscribe((notification) => {
      if (notification) this.render(notification);
    });
  }

  private render(notification: IToastNotification): void {
    // Création de l'élément de la notification
    const notificationBox = this.renderer.createElement('div');
    const boxColorClass = this.classMap.get(notification.type);
    const classesToAdd = ['alert', 'alert-dismissible', 'mb-1'];
    if (boxColorClass) classesToAdd.push('alert-' + boxColorClass);

    classesToAdd.forEach((x) => this.renderer.addClass(notificationBox, x));
    this.renderer.setAttribute(notificationBox, 'role', 'alert');
    this.renderer.setStyle(notificationBox, 'transition', `opacity 1000ms`);
    this.renderer.setStyle(notificationBox, 'opacity', '1');
    const content = this.renderer.createElement('div');

    const text = this.renderer.createText(notification.message);
    const closeButton = this.renderer.createElement('button');
    this.renderer.setAttribute(closeButton, 'type', 'button');
    this.renderer.addClass(closeButton, 'btn-close');
    this.renderer.setAttribute(closeButton, 'data-bs-dismiss', 'alert');
    this.renderer.setAttribute(closeButton, 'aria-label', 'Close');
    // Evénement au click pour fermeture de la notifications
    closeButton.addEventListener('click', () => {
      notificationBox.remove();
    });

    this.renderer.appendChild(content, text);
    this.renderer.appendChild(content, closeButton);
    this.renderer.appendChild(notificationBox, content);
    this.renderer.appendChild(this.container.nativeElement, notificationBox);
    // Timeout pour suppression de la notification en fonction de sa durée de vie
    if (notification.duration > 0) {
      setTimeout(() => {
        this.renderer.setStyle(notificationBox, 'opacity', '0');
        setTimeout(() => {
          this.renderer.removeChild(this.container.nativeElement, notificationBox);
        }, 1000);
      }, notification.duration);
    }
  }
}
