import { Component, Injector, OnInit, effect, inject, runInInjectionContext } from '@angular/core';
import { Collection } from '@metarisc/metarisc-js';
import { PaginationData } from '@metarisc/metarisc-js/lib/collection';
import { Notification } from '@metarisc/metarisc-js/src/model/Notification';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NotificationComponent } from 'src/app/components/notification/notification.component';
import { MetariscService } from 'src/app/services/metarisc-service/metarisc.service';
import { NotificationListenerService } from 'src/app/services/notification-listener-service/notification-listener.service';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { NoResultComponent } from 'src/app/shared/components/no-result/no-result.component';

@Component({
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.scss'],
  standalone: true,
  imports: [NoResultComponent, NotificationComponent, LoadingComponent, InfiniteScrollDirective],
})
export class NotificationPage implements OnInit {
  nbNotificationByPage = 15;

  unread_counter = this.notificationListenerService.unread_notification_counter;

  notifications?: Notification[];
  pagination?: PaginationData;

  metariscClient = this.metariscService.getMetarisc();
  notificationCollection!: Collection<Notification>;

  #injector = inject(Injector);
  scrollBlock?: HTMLDivElement;
  loading?: Promise<unknown>;

  constructor(
    private metariscService: MetariscService,
    private notificationListenerService: NotificationListenerService,
  ) {}

  ngOnInit(): void {
    const rightSide = document.getElementById('right-side');
    this.scrollBlock = rightSide as HTMLDivElement;

    runInInjectionContext(this.#injector, () => {
      // Ecoute de nouvelle notifications pour les afficher
      effect(() => {
        if (this.unread_counter() > 0) {
          this.notificationListenerService.resetUnreadCounter();
          this.getNotifications();
        }
      });
    });

    // Reset de compteur de notification non lues
    this.notificationListenerService.resetUnreadCounter();
    // Récupération des notifications
    this.getNotifications();
  }

  getNotifications(): void {
    this.notificationCollection =
      this.metariscClient.notifications?.paginateNotifications() as Collection<Notification>;
    this.loadPage(1);
  }

  loadPage(page: number): void {
    // Appel API
    this.loading = this.notificationCollection?.fetchPage(page, this.nbNotificationByPage).then((response) => {
      // Mise à jour des données de notification
      if (response.data.data) {
        if (!this.notifications) {
          this.notifications = response.data.data;
        } else this.notifications.push(...response.data.data);
      }
      // Mise à jour des données de pagination
      this.pagination = response.data.meta?.pagination as PaginationData;
      return response;
    });
  }
  onscroll(): void {
    this.loading?.then(() => {
      const totalPage = this.pagination?.total_pages;
      const currentPage = this.pagination?.current_page;
      if (currentPage && totalPage && currentPage < totalPage) {
        this.loadPage(currentPage + 1);
      }
    });
  }
}
