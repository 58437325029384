import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PEI } from '@metarisc/metarisc-js/lib/model/PEI';

@Component({
  selector: 'app-badge-deci-type',
  templateUrl: './badge-deci-type.component.html',
  styleUrls: ['./badge-deci-type.component.scss'],
  standalone: true,
  imports: [RouterLink, NgClass, NgIf],
})
export class BadgeDeciTypeComponent {
  @Input() data!: PEI;
  @Input() clickable = false;
  getBadgeClass(): string {
    return 'badge-genre-' + this.data.descriptif_technique?.type;
  }
}
