import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { ApiGouvInsee } from 'src/app/services/api-geo-gouv-service/api-adresse-gouv.model';
import { ApiAdresse } from 'src/app/services/api-geo-gouv-service/api-adresse-gouv.model';

@Injectable({
  providedIn: 'root',
})
export class ApiGeoGouvService {
  http = inject(HttpClient);

  getInfoCommuneByInseeCode(inseeCode: string): Observable<ApiGouvInsee> {
    return this.http.get<[ApiGouvInsee]>(`https://geo.api.gouv.fr/communes?code=${inseeCode}`).pipe(map((e) => e[0]));
  }

  getInfoCommuneByPostalCode(postalCode: string): Observable<ApiGouvInsee[]> {
    return this.http.get<[ApiGouvInsee]>(`https://geo.api.gouv.fr/communes?codePostal=${postalCode}`);
  }

  getInfoTypeVoieByArr(): Observable<ApiAdresse> {
    return this.http.get<ApiAdresse>('https://api-adresse.data.gouv.fr/search?q=13001&postcode=10200');
  }

  getAdresse(adresse: string, limit: number, postalCode = ''): Observable<ApiAdresse> {
    return this.http.get<ApiAdresse>(
      `https://api-adresse.data.gouv.fr/search?q=${adresse}&limit=${limit}&postcode=${postalCode}`,
    );
  }

  searchAdresseWithCoordinates(adresse: string, coordinates: Coordinate = [], limit: number): Observable<ApiAdresse> {
    return this.http.get<ApiAdresse>(
      `https://api-adresse.data.gouv.fr/search/?q=${adresse}&lon=${coordinates[0] || ''}&lat=${
        coordinates[1] || ''
      }&limit=${limit}`,
    );
  }

  getAdresseByCoordinates(coordinates: Coordinate, limit = 1): Observable<ApiAdresse> {
    return this.http.get<ApiAdresse>(
      `https://api-adresse.data.gouv.fr/reverse/?lon=${coordinates[0]}&lat=${coordinates[1]}&limit=${limit}`,
    );
  }
}
