import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-via-app',
  templateUrl: './via-app.component.html',
  standalone: true,
  imports: [],
})
export class ViaAppComponent {
  @Input() app!: string;
}
