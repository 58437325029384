import { Component } from '@angular/core';
import { NoResultComponent } from 'src/app/shared/components/no-result/no-result.component';

@Component({
  templateUrl: './notfound-page.component.html',
  styleUrls: ['./notfound-page.component.scss'],
  standalone: true,
  imports: [NoResultComponent],
})
export class NotfoundPage {}
