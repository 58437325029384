<div #searchBox *ngIf="haveControl.search">
  <app-adresse-autocomplete
    placeholder="Entrer une adresse, une ville, une rue ou des coordonées GPS (latitude, longitude)"
    id="search-box"
    (featureSelected)="searchAddress($event)"
    (addressString)="searchAddressString($event)"
  ></app-adresse-autocomplete>
</div>
<div
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="drop($event)"
  #displayLayers
  *ngIf="haveControl.displayLayers"
  class="d-flex column-gap-3 row-gap-1 text-nowrap flex-wrap mt-1"
  style="padding-inline: 50px"
>
  @for (layer of layers; track $index) {
    <button
      cdkDrag
      class="btn"
      [ngClass]="{
        'btn-primary': isVisibleLayer(layer),
        'btn-secondary': !isVisibleLayer(layer),
        'btn-sm': optionsDisplayLayers?.size === 'small',
        'btn-lg': optionsDisplayLayers?.size === 'large',
      }"
      (click)="optionsDisplayLayers?.toggle !== false && toggleLayerOnMap(layer)"
    >
      {{ layer.get('name') }}
    </button>
  }
</div>
<div #mapGeneral id="map" class="map"></div>
