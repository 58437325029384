<div class="row m-0 flex-row-reverse">
  <div class="col-12 col-lg-6 col-xxl-3 d-flex flex-column gap-4 p-1_5 pe-0 mt-2_5">
    <app-badge-organisation *ngIf="organisation" [organisation]="organisation"></app-badge-organisation>
    <app-news-feed [news]="rssFlux"></app-news-feed>
  </div>
  <div class="col-12 col-lg-6 col-xxl-9 ps-0">
    <app-no-result
      *ngIf="displayNoFeedMessage(); else notification"
      title="Aucune activité pour le moment !"
      description="En fonction de vos paramètres, vous verrez ici les mises à jour concernants vos dossiers et points adresse dans votre zone de compétence (et en fonction de votre profil)"
    ></app-no-result>
    <ng-template #notification>
      <div id="notifications" class="d-flex flex-column justify-content-center gap-2">
        <ng-container *ngIf="displayFeedList(); else placeholder">
          <div *ngFor="let date of getKeys(groupedFeed); let id = index">
            <span class="date-title fs-5 ff-semi-bold">{{ date | titlecase }}</span>
            <div class="frosted-glass mt-1 p-1 d-flex flex-column">
              <div *ngFor="let feed of groupedFeed[date]">
                <app-feed-message [data]="feed"></app-feed-message>
              </div>
            </div>
            <hr class="separator mt-2 mb-0" *ngIf="id !== getKeys(groupedFeed).length - 1" />
          </div>
          <app-paginate [pagination]="pagination" (loadPage)="loadPage($event)"></app-paginate>
        </ng-container>
        <ng-template #placeholder>
          <div class="placeholder-glow">
            <span class="placeholder date-title col-3"></span>
          </div>
          <div class="frosted-glass">
            <div *ngFor="let i of 10 | nbToArray" class="px-3 pt-3">
              <app-notification [isPlaceholder]="true"></app-notification>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
