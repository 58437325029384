import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { WMSCapabilities } from 'ol/format';
import { MapService } from 'src/app/services/map-service/map.service';
import { MapComponent } from 'src/app/shared/components/map/map.component';

@Component({
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss'],
  standalone: true,
  imports: [MapComponent],
})
export class MapPage implements OnInit {
  http = inject(HttpClient);
  mapService = inject(MapService);

  @ViewChild('refreshLayer') refreshLayer!: MapComponent;
  ngOnInit(): void {
    this.mapService.setView([251950.5, 6539949.5], 9.5);

    const paramCapabilities = '?REQUEST=GetCapabilities';

    this.http
      .get('https://srv-gcweb.sdis62.fr/geoconcept-web/wms' + paramCapabilities, { responseType: 'text' })
      .subscribe((data) => {
        const parser = new WMSCapabilities();
        const capabilities = parser.read(data);
        capabilities.Capability.Layer.Layer?.forEach((layer: any) => {
          this.mapService.addWMSLayer(
            'https://srv-gcweb.sdis62.fr/geoconcept-web/wms',
            { LAYERS: layer.Name },
            layer.Name,
          );
        });
        this.refreshLayer.refreshLayerList();
      });
  }
}
