import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OrganisationChoiceService } from '../services/organisation-choice.service';
import { MetariscService } from '../services/metarisc-service/metarisc.service';

export const organizationGuard: CanActivateFn = () => {
  const metariscService = inject(MetariscService);
  const orgaChoice = inject(OrganisationChoiceService);
  const router = inject(Router);

  const orgaId = orgaChoice.getOrganisationCookie();
  if (orgaId && orgaId.length > 0) {
    metariscService.getMetarisc().setActiveOrganisation(orgaId);
    return true;
  } else {
    router.navigateByUrl('/choix-organisation');
    return false;
  }
};
