import { Component, OnInit } from '@angular/core';
import { NotificationListenerService } from './services/notification-listener-service/notification-listener.service';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(private notificationListenerService: NotificationListenerService) {}

  ngOnInit(): void {
    this.notificationListenerService.start();
  }
}
