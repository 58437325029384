<div *ngIf="pagination" class="d-flex justify-content-center mt-2_5">
  <ngb-pagination
    [pageSize]="pagination.per_page"
    [collectionSize]="pagination.total"
    [(page)]="pagination.current_page"
    [maxSize]="3"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="changePage($event)"
  ></ngb-pagination>
</div>
