@if (error) {
  <p class="ff-light-italic text-danger">{{ error }}</p>
} @else {
  @if (load) {
    @if (placeholder) {
      @for (item of array; track $index) {
        <ng-container *ngTemplateOutlet="placeholder"></ng-container>
      }
    } @else if (display === 'inline') {
      <div
        [class]="'spinner-border text-' + color"
        [ngClass]="{ 'spinner-border-sm': size === 'small', 'spinner-border-lg': size === 'large' }"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    } @else {
      <div class="text-center">
        <div
          [class]="'spinner-border m-5 text-' + color"
          role="status"
          [ngClass]="{ 'spinner-border-sm': size === 'small', 'spinner-border-lg': size === 'large' }"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    }
  } @else {
    <ng-content></ng-content>
  }
}
