import { Component } from '@angular/core';
import { NoResultComponent } from 'src/app/shared/components/no-result/no-result.component';

@Component({
  selector: 'app-invitation-awaiting-validation-page',
  templateUrl: './invitation-awaiting-validation-page.component.html',
  styleUrls: ['./invitation-awaiting-validation-page.component.scss'],
  standalone: true,
  imports: [NoResultComponent],
})
export class InvitationAwaitingValidationPage {}
