import { Component } from '@angular/core';
import { NoResultComponent } from 'src/app/shared/components/no-result/no-result.component';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
  imports: [NoResultComponent],
})
export class ErrorPage {}
