import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FeedMessage } from '@metarisc/metarisc-js/lib/model/FeedMessage';
import { DateFromNowPipe } from 'src/app/shared/pipes/date-from-now.pipe';

@Component({
  selector: 'app-feed-message',
  templateUrl: './feed-message.component.html',
  styleUrls: ['./feed-message.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, DateFromNowPipe],
})
export class FeedMessageComponent {
  @Input() isPlaceholder = false;
  @Input() data!: FeedMessage;
}
