import { Component, Input } from '@angular/core';
import { DossierComplet } from 'src/app/models/dossier.model';
import { RecapDossierComponent } from '../../recap-dossier/recap-dossier.component';

@Component({
  selector: 'app-list-dossier-item',
  templateUrl: './list-dossier-item.component.html',
  styleUrls: ['./list-dossier-item.component.scss'],
  standalone: true,
  imports: [RecapDossierComponent],
})
export class ListDossierItemComponent {
  @Input() dossiers: DossierComplet[] = [];
}
