import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { rssItem } from 'src/app/services/rss-feed/rss-feed.service';
import { DateFromNowPipe } from 'src/app/shared/pipes/date-from-now.pipe';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, AsyncPipe, DateFromNowPipe],
})
export class NewsFeedComponent {
  @Input() news?: Observable<rssItem[]>;
}
