import { Injectable, computed, signal } from '@angular/core';
import { SidebarCustomItem, SidebarCustomUniverse } from './sidebar-custom.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  constructor() {
    this.initValidLocalStorageSidebar();
  }

  static LOCAL_STORAGE_SIDEBAR = 'metarisc-sidebar-custom';

  private sidebarBase: SidebarCustomUniverse[] = [
    {
      name: 'deci',
      label: 'DECI',
      icon: '/assets/images/sidebar/deci.svg',
      items: [],
    },
    {
      name: 'erp',
      label: 'ERP',
      icon: '/assets/images/sidebar/erp.svg',
      items: [],
    },
    { name: 'habitation', label: 'Habitation', icon: '/assets/images/sidebar/deci.svg', items: [] },
    { name: 'igh', label: 'IGH', icon: '/assets/images/sidebar/deci.svg', items: [] },
    { name: 'rassemblement', label: 'Rassemblement', icon: '/assets/images/sidebar/deci.svg', items: [] },
    { name: 'icpe', label: 'ICPE', icon: '/assets/images/sidebar/deci.svg', items: [] },
    { name: 'autres', label: 'Autres', icon: '/assets/images/sidebar/deci.svg', items: [] },
  ];

  private permanentSidebar: SidebarCustomItem[] = [
    { name: 'Créer un dossier', url: '/deci/nouveau-dossier', tabName: 'deci' },
    { name: 'Déclarer un PEI', url: '/deci/declaration', tabName: 'deci' },
    { name: 'Créer une tournée', url: '/deci/roa', tabName: 'deci' },
    { name: 'Gérer les tournées', url: '/deci/roa/liste', tabName: 'deci' },
    { name: 'Créer un dossier', url: '/erp/nouveau-dossier', tabName: 'erp' },
    { name: 'Créer un ERP', url: '/erp/declaration', tabName: 'erp' },
    { name: 'Gérer les commissions', url: '/erp/commissions', tabName: 'erp' },
  ];

  //Liens ajouté via une page de l'app
  private pinedLinksItems = signal<SidebarCustomItem[]>([]);
  actualUniverse: string = '';

  //Liens enregistrées dans le local storage
  private localStorageItems = signal<SidebarCustomItem[]>([]);

  //Sidebar affichée. Se met à jour à chaque changement sur pinedLinksItems ou localStorageItems
  sidebar = computed<SidebarCustomUniverse[]>(() => {
    const localStorageSidebar = this.localStorageItems();
    const pinedLinks = this.pinedLinksItems();

    return this.mergeSidebar(localStorageSidebar, pinedLinks);
  });

  getLocalStorageSidebar(): SidebarCustomItem[] {
    return this.localStorageItems();
  }
  initValidLocalStorageSidebar(): void {
    //On vérifie si le local storage est valide sinon on le nettoie
    try {
      const localStorageSidebar = JSON.parse(localStorage.getItem(SidebarService.LOCAL_STORAGE_SIDEBAR) || '[]');
      localStorage.setItem(SidebarService.LOCAL_STORAGE_SIDEBAR, JSON.stringify(localStorageSidebar));
      if (localStorageSidebar[0].items) {
        throw new Error('Other version of sidebar');
      }
      this.localStorageItems.set(localStorageSidebar);
    } catch (error) {
      localStorage.setItem(SidebarService.LOCAL_STORAGE_SIDEBAR, JSON.stringify([]));
    }
  }

  //lien ajouté via une page de l'app
  addPinableLink(link: SidebarCustomItem): void {
    const pinedLinksTemp = [...this.pinedLinksItems()];

    if (pinedLinksTemp.some((l) => l.url === link.url)) return;
    pinedLinksTemp.push(link);
    this.pinedLinksItems.set(pinedLinksTemp);
  }

  addLocalStorageLink(link: SidebarCustomItem): void {
    this.localStorageItems.update((links) => {
      links.push(link);
      return [...links];
    });
    this.setLocalStorageSidebar(this.localStorageItems());
  }

  removeLocalStorageLink(link: SidebarCustomItem): void {
    this.localStorageItems.update((links) => {
      const item = links.find((l) => l.url === link.url);
      if (item) {
        links.splice(links.indexOf(item), 1);
      }
      return [...links];
    });
    this.setLocalStorageSidebar(this.localStorageItems());
  }

  mergeSidebar(localStorageItem: SidebarCustomItem[], pinableLinks: SidebarCustomItem[]): SidebarCustomUniverse[] {
    const newPinableLinks = pinableLinks.filter((u) => !this.localStorageItems().some((l) => l.url === u.url));

    const mergedSidebar = this.sidebarBase.map((universe) => {
      //On vide les items des univers
      universe.items = [];

      //On ajoute les liens pinable (addPinableLink) dans l'univers correspondant
      if (newPinableLinks.length > 0) {
        universe.items.push(...pinableLinks.filter((u) => u.tabName === universe.name));
      }

      //On ajoute les liens enregistrés dans le local storage dans l'univers correspondant
      const localStorageItems = localStorageItem.filter((u) => u.tabName === universe.name);
      if (localStorageItems && localStorageItems.length > 0) {
        universe.items.push(...localStorageItems);
      }

      //On ajoute les liens permanents dans l'univers correspondant
      const permanentItems = this.permanentSidebar.filter((u) => u.tabName === universe.name);
      universe.items.push(...permanentItems);

      return universe;
    });
    return mergedSidebar.filter((u) => u.items.length > 0);
  }

  updateRoute(url: string): void {
    const pined = this.pinedLinksItems().filter((l) => {
      return url.startsWith(l.url) && l.layout;
    });

    this.pinedLinksItems.set(pined);

    const universeList = this.sidebarBase.map((u) => u.name);
    const actualUniverse = universeList.find((u) => url.includes(u));
    if (actualUniverse) {
      this.actualUniverse = actualUniverse;
    } else {
      this.actualUniverse = 'autres';
    }
  }

  setLocalStorageSidebar(sidebar: SidebarCustomItem[]): void {
    localStorage.setItem(SidebarService.LOCAL_STORAGE_SIDEBAR, JSON.stringify(sidebar));
    this.localStorageItems.set(sidebar);
  }

  toggleLink(link: SidebarCustomItem): void {
    if (link.checked === true) {
      link.checked = false;
      this.removeLocalStorageLink(link);
    } else {
      link.checked = true;
      this.addLocalStorageLink(link);
    }
  }

  getRoutePath(route: ActivatedRoute): string {
    let path = '';
    let currentRoute = route;

    // Boucle à travers les parents jusqu'au composant sans les enfants
    while (currentRoute.parent) {
      const urlSegment = currentRoute.snapshot.url.map((segment) => segment.path).join('/');
      if (urlSegment) {
        path = urlSegment + (path ? '/' + path : '');
      }
      currentRoute = currentRoute.parent;
    }

    return '/' + decodeURI(path);
  }
}
